import Decimal from 'decimal.js';

/**
 * Rounds a number down to the specified number of decimal places, avoiding precision issues.
 * @param {Number|String|Object} value
 * @param {Number} decimals
 * @returns {Number}
 */
const floor = (value = 0, decimals = 2, fallback = 0) => {
    if (isNaN(value) || value === null) {
        return fallback;
    }

    let decimalValue = new Decimal(value);
    let truncatedValue = decimalValue.toDecimalPlaces(decimals, Decimal.ROUND_DOWN);

    return parseFloat(truncatedValue.toString());
};

window.floor = floor;

export default floor;
