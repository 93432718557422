import {
    BaseModel,
    FundManager,
    Provider,
    Nominee,
    Custodian,
    FeePlan,
    FundStructure,
    InvestmentStrategy,
    IhtSector,
    FundNews
} from '@/models';
import FundApi from '@/api/FundApi';
import SDRLabelEnum from '@/enums/SDRLabel';

export class Fund extends BaseModel {
    static entity = 'funds';
    static Api = FundApi;

    static fields() {
        return {
            id: this.string(null).nullable(),
            name: this.string(''),
            gi_ref: this.string(null).nullable(),
            type: this.string(null).nullable(),
            status: this.string(null).nullable(),
            summary: this.attr(null).nullable(),
            overview: this.attr(null).nullable(),
            esg_qualifying: this.boolean(false),
            launch_at: this.string(null).nullable(),
            close_at: this.string(null).nullable(),
            iht_type: this.string(null).nullable(),
            iht_relief: this.boolean(null).nullable(),
            deployment_timeline: this.string(null).nullable(),
            deployment_by_yearend: this.boolean(false),
            description: this.attr(null).nullable(),
            target_return: this.number(null).nullable(),
            exit_strategy: this.attr(null).nullable(),
            funds_raised: this.attr(this.defaultMoney).nullable(),
            tax_status: this.string(null).nullable(),
            currency: this.string(null).nullable(),
            aum: this.attr(this.defaultMoney).nullable(),
            discretionary: this.boolean(null).nullable(),
            news_items: this.hasMany(FundNews, 'fund_id'),
            sdr_label: this.enum(SDRLabelEnum, SDRLabelEnum.NO_LABEL).nullable(),

            fund_manager_id: this.string(null).nullable(),
            fund_manager: this.belongsTo(FundManager, 'fund_manager_id'),

            provider_id: this.string(null).nullable(),
            provider: this.belongsTo(Provider, 'provider_id'),

            nominee_id: this.string(null).nullable(),
            nominee: this.belongsTo(Nominee, 'nominee_id'),

            custodian_id: this.string(null).nullable(),
            custodian: this.belongsTo(Custodian, 'custodian_id'),

            fee_plan_id: this.string(null).nullable(),
            fee_plan: this.belongsTo(FeePlan, 'fee_plan_id'),

            fund_structure_id: this.string(null).nullable(),
            fund_structure: this.belongsTo(FundStructure, 'fund_structure_id'),

            investment_strategy_id: this.string(null).nullable(),
            investment_strategy: this.belongsTo(InvestmentStrategy, 'investment_strategy_id'),

            iht_sector_id: this.string(null).nullable(),
            iht_sector: this.belongsTo(IhtSector, 'iht_sector_id')
        };
    }
}

export default Fund;
