import { Media, Investor, Adviser, Holding, Transaction, Product, Fund, Tranche } from '@/models';
import MediaTypeEnum from '@/enums/media/type';
import MediaInvestorTypeEnum from '@/enums/media/investor/type';
import MediaInvestorApi from '@/api/MediaInvestorApi';
import QuarantineStatusEnum from '@/enums/quarantineStatus';

export class MediaInvestor extends Media {
    static entity = 'mediaInvestor';
    static Api = MediaInvestorApi;

    static fields() {
        return {
            ...super.fields(),
            type: this.enum(MediaInvestorTypeEnum),
            library_type: this.string(MediaTypeEnum.INVESTOR),
            document_date: this.string(null).nullable(),
            quarantine_status: this.enum(QuarantineStatusEnum).nullable(),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),

            holding_id: this.string(null).nullable(),
            holding: this.belongsTo(Holding, 'holding_id'),

            transaction_id: this.string(null).nullable(),
            transaction: this.belongsTo(Transaction, 'transaction_id'),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),

            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id'),

            tranche_id: this.string(null).nullable(),
            tranche: this.belongsTo(Tranche, 'tranche_id')
        };
    }

    get is_published() {
        return this.quarantine_status === QuarantineStatusEnum.PUBLISHED;
    }

    get is_quarantined() {
        return !this.is_published;
    }
}

export default MediaInvestor;
