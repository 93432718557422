import Calculator from '@/calc/Calculator';
import ProposalSolveAmountForEnum from '@/enums/proposal/solveAmountFor';
import FeeCollectionEnum from '@/enums/feeCollection';
import TaxStatusEnum from '@/enums/taxStatus';
import floor from '@/lib/helpers/floor';

export class ProposalCalculator extends Calculator {
    constructor(properties = {}) {
        super();
        for (const key in properties) {
            if (properties[`${key}`] !== null && properties[`${key}`] !== undefined) {
                this[`${key}`] = properties[`${key}`];
            }
        }
    }

    /**
     * Create a new ProposalCalculator instance based on the given solve amount for.
     * @param {Object} properties
     * @returns {ProposalCalculator}
     */
    static create(properties = {}) {
        const solveAmountFor = properties._solve_amount_for || ProposalSolveAmountForEnum.APPLICATION_AMOUNT;

        if (solveAmountFor === ProposalSolveAmountForEnum.APPLICATION_AMOUNT) {
            const ProposalFeesAddedCalculator = require('./ProposalFeesAddedCalculator').default;
            return new ProposalFeesAddedCalculator(properties);
        } else if (solveAmountFor === ProposalSolveAmountForEnum.CLEARED_FUNDS) {
            const ProposalFeesDeductedCalculator = require('./ProposalFeesDeductedCalculator').default;
            return new ProposalFeesDeductedCalculator(properties);
        } else {
            throw new Error('Invalid solve amount for');
        }
    }

    clone(properties = {}) {
        properties = { ...this.props, ...properties };
        return ProposalCalculator.create(properties);
    }

    /**
     * Rebase and recalculate the calculator based on the provided properties.
     * @param {Object} properties
     * @returns {ProposalCalculator}
     */
    rebase(properties = {}) {
        properties = { ...this.props, ...properties };
        const instance = ProposalCalculator.create(properties);

        instance.logWarn('Rebased calculator to solve amount for', instance._solve_amount_for);

        instance.recalculate();
        return instance;
    }

    _solve_amount_for = ProposalSolveAmountForEnum.APPLICATION_AMOUNT;

    _currency = 'GBP';
    _tax_status = null;
    _adviser_fees_get_tax_relief = false;

    _default_initial_adviser_fee = null;
    _default_initial_adviser_fee_percentage = null;
    _default_annual_adviser_fee = null;
    _default_annual_adviser_fee_percentage = null;
    _default_annual_platform_fee = null;
    _default_annual_platform_fee_percentage = null;

    _initial_amount = 0;
    _amount = 0;
    _cleared_funds = 0;
    _application_amount = 0;

    _initial_adviser_fee = 0;
    _initial_adviser_fee_percentage = 0;
    _initial_adviser_fee_use_percentage = true;
    _initial_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PROVIDER;
    _initial_adviser_fee_vat = false;

    _annual_adviser_fee = 0;
    _annual_adviser_fee_percentage = 0;
    _annual_adviser_fee_use_percentage = true;
    _annual_adviser_fee_years_upfront = 2;
    _annual_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PLATFORM;
    _annual_adviser_fee_vat = false;

    _annual_platform_fee = 0;
    _annual_platform_fee_percentage = 0;
    _annual_platform_fee_use_percentage = true;
    _annual_platform_fee_years_upfront = 2;

    _log = [];

    _fee_recalculation_count = 0;

    log(message = '', data = {}, level = 'DEBUG') {
        this._log.push({
            timestamp: new Date().toISOString(),
            level: level.toUpperCase(),
            message,
            data,
            metadata: {
                calculator: this.constructor.name,
                solve_amount_for: this._solve_amount_for,
                currency: this._currency,
                tax_status: this._tax_status
            }
        });
    }

    logInfo(message = '', data = {}) {
        this.log(message, data, 'INFO');
    }

    logWarn(message = '', data = {}) {
        this.log(message, data, 'WARN');
    }

    logError(message = '', data = {}) {
        this.log(message, data, 'ERROR');
    }

    resetLogs() {
        this._log = [];
    }

    get logs() {
        return (
            this._log
                .map(({ timestamp, level, message, data }) => {
                    let text = `[${timestamp}] ${level.padEnd(5, ' ')} ${message}`;
                    if (data && Object.keys(data).length > 0) {
                        text += `: ${JSON.stringify(data)}`;
                    } else if (data && Array.isArray(data) && data.length > 0) {
                        text += `: [${data.join(', ')}]`;
                    } else if (typeof data === 'string' && data.length > 0) {
                        text += ` "${data}"`;
                    }
                    return text;
                })
                .join('\n') + '\n\n'
        );
    }

    reset(keys = []) {
        const defaultValues = {
            initial_amount: 0,
            amount: 0,
            cleared_funds: 0,
            application_amount: 0,

            initial_adviser_fee: 0,
            initial_adviser_fee_percentage: 0,
            initial_adviser_fee_use_percentage: true,
            initial_adviser_fee_collection: FeeCollectionEnum.FACILITATED_PROVIDER,
            initial_adviser_fee_vat: false,

            annual_adviser_fee: 0,
            annual_adviser_fee_percentage: 0,
            annual_adviser_fee_use_percentage: true,
            annual_adviser_fee_years_upfront: 2,
            annual_adviser_fee_collection: FeeCollectionEnum.FACILITATED_PLATFORM,
            annual_platform_fee_vat: false,

            annual_platform_fee: 0,
            annual_platform_fee_percentage: 0,
            annual_platform_fee_use_percentage: true,
            annual_platform_fee_years_upfront: 2
        };

        const resetAll = !Array.isArray(keys) || keys.length === 0;

        const reset = key => {
            if (resetAll || keys.includes(key)) {
                this[`_${key}`] = defaultValues[`${key}`];
            }
        };

        Object.keys(defaultValues).forEach(key => reset(key));

        this.logWarn('Resetting values to defaults', { keys });
    }

    resetAdviserFees() {
        this._initial_adviser_fee = 0;
        this._initial_adviser_fee_percentage = 0;
        this._initial_adviser_fee_use_percentage = true;
        this._initial_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PROVIDER;
        this._initial_adviser_fee_vat = false;

        this._annual_adviser_fee = 0;
        this._annual_adviser_fee_percentage = 0;
        this._annual_adviser_fee_use_percentage = true;
        this._annual_adviser_fee_years_upfront = 2;
        this._annual_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PLATFORM;
        this._annual_platform_fee_vat = false;

        this.logWarn('Resetting adviser fees to defaults');
    }

    resetPlatformFees() {
        this._annual_platform_fee = 0;
        this._annual_platform_fee_percentage = 0;
        this._annual_platform_fee_use_percentage = true;
        this._annual_platform_fee_years_upfront = 2;

        this.logWarn('Resetting platform fees to defaults');
    }

    get props() {
        return Object.keys(this)
            .filter(key => key.startsWith('_'))
            .reduce((result, key) => {
                result[`${key}`] = this[`${key}`];
                return result;
            }, {});
    }

    get output() {
        return {
            solve_amount_for: this.solve_amount_for,
            adviser_fees_get_tax_relief: this.adviser_fees_get_tax_relief,
            //
            initial_amount: this.toMoney(this.initial_amount),
            amount: this.toMoney(this.amount),
            cleared_funds: this.toMoney(this.cleared_funds),
            application_amount: this.toMoney(this.application_amount),
            //
            total_amount_including_fees: this.toMoney(this.total_amount_including_fees),
            maximum_tax_relief: this.toMoney(this.maximum_tax_relief),
            total_subscription: this.toMoney(this.total_subscription),
            //
            initial_adviser_fee: this.toMoney(this.initial_adviser_fee, null),
            initial_adviser_fee_percentage: this.initial_adviser_fee_percentage,
            initial_adviser_fee_use_percentage: this.initial_adviser_fee_use_percentage,
            initial_adviser_fee_collection: this.initial_adviser_fee_collection,
            initial_adviser_fee_vat: this.initial_adviser_fee_vat,
            //
            annual_adviser_fee: this.toMoney(this.annual_adviser_fee, null),
            annual_adviser_fee_percentage: this.annual_adviser_fee_percentage,
            annual_adviser_fee_use_percentage: this.annual_adviser_fee_use_percentage,
            annual_adviser_fee_years_upfront: this.annual_adviser_fee_years_upfront,
            annual_adviser_fee_collection: this.annual_adviser_fee_collection,
            annual_adviser_fee_vat: this.annual_adviser_fee_vat,
            //
            annual_platform_fee: this.toMoney(this.annual_platform_fee, null),
            annual_platform_fee_percentage: this.annual_platform_fee_percentage,
            annual_platform_fee_use_percentage: this.annual_platform_fee_use_percentage,
            annual_platform_fee_years_upfront: this.annual_platform_fee_years_upfront
        };
    }

    get interim_percentage() {
        // The percentage to calculate interim amount on
        return 1;
    }

    get interim_amount() {
        // The amount to base percentage calculations on
        return 0;
    }

    recalculate() {
        this.recalculateFees();
        this.recalculateTotals();
    }

    recalculateTotals() {
        this.recalculateAmount();
        this.recalculateClearedFunds();
        this.recalculateApplicationAmount();
    }

    recalculateFees() {
        this._fee_recalculation_count++;

        const interimAmount = this.interim_amount;

        this.recalculateInitialAdviserFee(interimAmount);
        this.recalculateAnnualAdviserFee(interimAmount);
        this.recalculateAnnualPlatformFee(interimAmount);

        if (interimAmount !== this.interim_amount && this._fee_recalculation_count < 10) {
            this.recalculateFees();
        } else {
            this._fee_recalculation_count = 0;
        }
    }

    recalculateInitialAdviserFee(interimAmount = 0, only = null) {
        interimAmount = interimAmount || this.interim_amount;

        if ((only && only === 'amount') || this._initial_adviser_fee_use_percentage) {
            this._initial_adviser_fee = interimAmount
                ? this.$m(interimAmount, { precision: 8 }).multiply(this._initial_adviser_fee_percentage).value
                : 0;

            this.log('Recalculating initial adviser fee amount', {
                calculation: interimAmount
                    ? `${this.$m(interimAmount, { precision: 8 }).value} * ${this._initial_adviser_fee_percentage}`
                    : 'None',
                output: this._initial_adviser_fee
            });
        }

        if ((only && only === 'percentage') || !this._initial_adviser_fee_use_percentage) {
            this._initial_adviser_fee_percentage =
                interimAmount && this._initial_adviser_fee
                    ? this.$m(this._initial_adviser_fee, { precision: 4 }).divide(interimAmount).value
                    : 0;

            this.log('Recalculating initial adviser fee percentage', {
                calculation:
                    interimAmount && this._initial_adviser_fee
                        ? `${this.$m(this._initial_adviser_fee, { precision: 4 }).value} / ${interimAmount}`
                        : 'None',
                output: this._initial_adviser_fee_percentage
            });
        }
    }

    resetInitialAdviserFee() {
        this._initial_adviser_fee = this._default_initial_adviser_fee;
        this._initial_adviser_fee_percentage = this._default_initial_adviser_fee_percentage;
        this.log('Resetting initial adviser fee', {}, 'INFO');
    }

    recalculateAnnualAdviserFee(interimAmount = 0, only = null) {
        interimAmount = interimAmount || this.interim_amount;

        if ((only && only === 'amount') || this._annual_adviser_fee_use_percentage) {
            this._annual_adviser_fee = interimAmount
                ? this.$m(interimAmount, { precision: 8 }).multiply(this._annual_adviser_fee_percentage).value
                : 0;

            this.log('Recalculating annual adviser fee amount', {
                calculation: interimAmount
                    ? `${this.$m(interimAmount, { precision: 8 }).value} * ${this._annual_adviser_fee_percentage}`
                    : 'None',
                output: this._annual_adviser_fee
            });
        }

        if ((only && only === 'percentage') || !this._annual_adviser_fee_use_percentage) {
            this._annual_adviser_fee_percentage =
                interimAmount && this._annual_adviser_fee
                    ? this.$m(this._annual_adviser_fee, { precision: 4 }).divide(interimAmount).value
                    : 0;

            this.log('Recalculating annual adviser fee percentage', {
                calculation:
                    interimAmount && this._annual_adviser_fee
                        ? `${this.$m(this._annual_adviser_fee, { precision: 4 }).value} / ${interimAmount}`
                        : 'None',
                output: this._annual_adviser_fee_percentage
            });
        }
    }

    resetAnnualAdviserFee() {
        this._annual_adviser_fee = this._default_annual_adviser_fee;
        this._annual_adviser_fee_percentage = this._default_annual_adviser_fee_percentage;
        this.log('Resetting annual adviser fee', {}, 'INFO');
    }

    recalculateAnnualPlatformFee(interimAmount = 0, only = null) {
        interimAmount = interimAmount || this.interim_amount;

        if ((only && only === 'amount') || this._annual_platform_fee_use_percentage) {
            this._annual_platform_fee = interimAmount
                ? this.$m(interimAmount, { precision: 8 }).multiply(this._annual_platform_fee_percentage).value
                : 0;

            this.log('Recalculating annual platform fee amount', {
                calculation: interimAmount
                    ? `${this.$m(interimAmount, { precision: 8 }).value} * ${this._annual_platform_fee_percentage}`
                    : 'None',
                output: this._annual_platform_fee
            });
        }

        if ((only && only === 'percentage') || !this._annual_platform_fee_use_percentage) {
            this._annual_platform_fee_percentage =
                interimAmount && this._annual_platform_fee
                    ? this.$m(this._annual_platform_fee, { precision: 4 }).divide(interimAmount).value
                    : 0;

            this.log('Recalculating annual platform fee percentage', {
                calculation:
                    interimAmount && this._annual_platform_fee
                        ? `${this.$m(this._annual_platform_fee, { precision: 4 }).value} / ${interimAmount}`
                        : 'None',
                output: this._annual_platform_fee_percentage
            });
        }
    }

    resetAnnualPlatformFee() {
        this._annual_platform_fee = this._default_annual_platform_fee;
        this._annual_platform_fee_percentage = this._default_annual_platform_fee_percentage;
        this.log('Resetting annual platform fee', {}, 'INFO');
    }

    recalculateAmount() {
        // this._amount =
    }

    recalculateApplicationAmount() {
        // this._application_amount =
    }

    recalculateClearedFunds() {
        // this._cleared_funds =
    }

    // Options

    get solve_amount_for() {
        return this._solve_amount_for;
    }
    set solve_amount_for(value) {
        this._solve_amount_for = value;
        this.log('Setting solve amount for', { value: this._solve_amount_for }, 'INFO');
    }

    get is_fees_added() {
        return this.solve_amount_for === 'APPLICATION_AMOUNT';
    }

    get is_fees_deducted() {
        return this.solve_amount_for === 'CLEARED_FUNDS';
    }

    get tax_status() {
        return this._tax_status;
    }
    set tax_status(value) {
        this._tax_status = value;
        this.log('Setting tax status', { value: this._tax_status }, 'INFO');
        this.recalculate();
    }
    get alts() {
        return this.tax_status === TaxStatusEnum.ALTS;
    }
    set alts(value) {
        this.tax_status = value ? TaxStatusEnum.ALTS : null;
    }
    get eis() {
        return this.tax_status === TaxStatusEnum.EIS;
    }
    set eis(value) {
        this.tax_status = value ? TaxStatusEnum.EIS : null;
    }
    get hybrid() {
        return this.tax_status === TaxStatusEnum.HYBRID;
    }
    set hybrid(value) {
        this.tax_status = value ? TaxStatusEnum.HYBRID : null;
    }
    get iht() {
        return this.tax_status === TaxStatusEnum.IHT;
    }
    set iht(value) {
        this.tax_status = value ? TaxStatusEnum.IHT : null;
    }
    get nq() {
        return this.tax_status === TaxStatusEnum.NQ;
    }
    set nq(value) {
        this.tax_status = value ? TaxStatusEnum.NQ : null;
    }
    get seis() {
        return this.tax_status === TaxStatusEnum.SEIS;
    }
    set seis(value) {
        this.tax_status = value ? TaxStatusEnum.SEIS : null;
    }
    get seiseis() {
        return this.tax_status === TaxStatusEnum.SEISEIS;
    }
    set seiseis(value) {
        this.tax_status = value ? TaxStatusEnum.SEISEIS : null;
    }
    get sitr() {
        return this.tax_status === TaxStatusEnum.SITR;
    }
    set sitr(value) {
        this.tax_status = value ? TaxStatusEnum.SITR : null;
    }
    get vct() {
        return this.tax_status === TaxStatusEnum.VCT;
    }
    set vct(value) {
        this.tax_status = value ? TaxStatusEnum.VCT : null;
    }
    get pmi() {
        return this.tax_status === TaxStatusEnum.PMI;
    }
    set pmi(value) {
        this.tax_status = value ? TaxStatusEnum.PMI : null;
    }

    get floored() {
        return this.vct;
    }

    get adviser_fees_get_tax_relief() {
        return this._adviser_fees_get_tax_relief;
    }
    set adviser_fees_get_tax_relief(value) {
        this._adviser_fees_get_tax_relief = value;
        this.log('Setting adviser fees get tax relief', { value: this._adviser_fees_get_tax_relief }, 'INFO');
        this.recalculate();
    }

    // Totals

    get initial_amount() {
        return floor(this._initial_amount, 2);
    }
    set initial_amount(value) {
        this._initial_amount = this.fromMoney(value);
        this.log('Setting initial amount', { value: this._initial_amount }, 'INFO');
    }

    get amount() {
        return floor(this._amount, this.floored ? 0 : 2);
    }
    set amount(value) {
        this._amount = this.fromMoney(value);
        this.log('Setting amount', { value: this._amount }, 'INFO');
    }

    get application_amount() {
        return floor(this._application_amount, this.floored ? 0 : 2);
    }
    set application_amount(value) {
        this._application_amount = this.fromMoney(value);
        this.log('Setting application amount', { value: this._application_amount }, 'INFO');
    }

    get cleared_funds() {
        return floor(this._cleared_funds, 2);
    }
    set cleared_funds(value) {
        this._cleared_funds = this.fromMoney(value);
        this.log('Setting cleared funds', { value: this._cleared_funds }, 'INFO');
    }

    get total_amount_including_fees() {
        let total = this.$m(this._amount);

        total = total.add(this.initial_adviser_fee);

        if (this.annual_adviser_fee_collection !== FeeCollectionEnum.FACILITATED_PROVIDER) {
            total = total.add(
                floor(this.$m(this.annual_adviser_fee).multiply(this.annual_adviser_fee_years_upfront).value, 2)
            );
        }

        total = total.add(
            floor(this.$m(this.annual_platform_fee).multiply(this.annual_platform_fee_years_upfront).value, 2)
        );

        return floor(total.value, 2);
    }

    get tax_relief_multiplier() {
        if (this.seis) {
            return 0.5;
        } else if (this.iht) {
            return 0;
        }

        return 0.3;
    }

    get maximum_tax_relief() {
        let total = this.$m(this._amount);

        if (
            this.adviser_fees_get_tax_relief &&
            this.initial_adviser_fee_collection === FeeCollectionEnum.FACILITATED_PROVIDER
        ) {
            total = total.add(this.initial_adviser_fee);
        }

        return floor(total.multiply(this.tax_relief_multiplier).value, 2);
    }

    get total_subscription() {
        let total = this.$m(this.application_amount);

        if (this.initial_adviser_fee_collection === FeeCollectionEnum.FACILITATED_PROVIDER) {
            total = total.subtract(this.initial_adviser_fee);
        }

        return floor(total.value, this.floored ? 0 : 2);
    }

    get total_adviser_fees() {
        return this.$m(this.initial_adviser_fee_total).add(this.$m(this.annual_adviser_fee_total)).value || 0;
    }

    get total_adviser_fees_percentage() {
        return this.$m(this.total_adviser_fees, { precision: 6 }).divide(this.amount).value || 0;
    }

    get total_platform_fees() {
        return this.$m(this.annual_platform_fee_total).value || 0;
    }

    // Initial Adviser Fee

    get initial_adviser_fee() {
        return floor(this._initial_adviser_fee, this.floored ? 0 : 2, null);
    }
    get initial_adviser_fee_total() {
        return floor(this._initial_adviser_fee, this.floored ? 0 : 2);
    }
    get initial_adviser_fee_by_collection() {
        if (this.initial_adviser_fee_collection === FeeCollectionEnum.DIRECT) {
            return 0;
        }

        return this.initial_adviser_fee_total;
    }
    set initial_adviser_fee(value) {
        this._initial_adviser_fee = this.fromMoney(value, null);
        this.log('Setting initial adviser fee', { value: this._initial_adviser_fee }, 'INFO');
        this.recalculateInitialAdviserFee(0, 'percentage');
        this.recalculateTotals();
    }

    get initial_adviser_fee_percentage() {
        return this._initial_adviser_fee_percentage;
    }
    set initial_adviser_fee_percentage(value) {
        this._initial_adviser_fee_percentage = value;
        this.log('Setting initial adviser fee percentage', { value: this._initial_adviser_fee_percentage }, 'INFO');
        this.recalculateInitialAdviserFee(0, 'amount');
        this.recalculateTotals();
    }

    get initial_adviser_fee_use_percentage() {
        return this._initial_adviser_fee_use_percentage;
    }
    set initial_adviser_fee_use_percentage(value) {
        this._initial_adviser_fee_use_percentage = value;
        this.log(
            'Setting initial adviser fee use percentage',
            { value: this._initial_adviser_fee_use_percentage },
            'INFO'
        );
        this.recalculateTotals();
    }

    get initial_adviser_fee_collection() {
        return this._initial_adviser_fee_collection || FeeCollectionEnum.FACILITATED_PROVIDER;
    }
    set initial_adviser_fee_collection(value) {
        this._initial_adviser_fee_collection = value;
        this.log('Setting initial adviser fee collection', { value: this._initial_adviser_fee_collection }, 'INFO');
        this.recalculateTotals();
    }

    get initial_adviser_fee_vat() {
        return this._initial_adviser_fee_vat;
    }
    set initial_adviser_fee_vat(value) {
        this._initial_adviser_fee_vat = value;
    }

    // Annual Adviser Fee

    get annual_adviser_fee() {
        return floor(this._annual_adviser_fee, 2, null);
    }
    get annual_adviser_fee_total() {
        return floor(this.$m(this.annual_adviser_fee).multiply(this.annual_adviser_fee_years_upfront).value, 2);
    }
    get annual_adviser_fee_by_collection() {
        if (this._annual_adviser_fee_collection !== FeeCollectionEnum.FACILITATED_PLATFORM) {
            return 0;
        }

        return this.annual_adviser_fee_total;
    }
    set annual_adviser_fee(value) {
        this._annual_adviser_fee = this.fromMoney(value, null);
        this.log('Setting annual adviser fee', { value: this._annual_adviser_fee }, 'INFO');
        this.recalculateAnnualAdviserFee(0, 'percentage');
        this.recalculateTotals();
    }

    get annual_adviser_fee_percentage() {
        return this._annual_adviser_fee_percentage;
    }
    set annual_adviser_fee_percentage(value) {
        this._annual_adviser_fee_percentage = value;
        this.log('Setting annual adviser fee percentage', { value: this._annual_adviser_fee_percentage }, 'INFO');
        this.recalculateAnnualAdviserFee(0, 'amount');
        this.recalculateTotals();
    }

    get annual_adviser_fee_use_percentage() {
        return this._annual_adviser_fee_use_percentage;
    }
    set annual_adviser_fee_use_percentage(value) {
        this._annual_adviser_fee_use_percentage = value;
        this.log(
            'Setting annual adviser fee use percentage',
            { value: this._annual_adviser_fee_use_percentage },
            'INFO'
        );
        this.recalculateTotals();
    }

    get annual_adviser_fee_years_upfront() {
        return this._annual_adviser_fee_years_upfront || 0;
    }
    set annual_adviser_fee_years_upfront(value) {
        this._annual_adviser_fee_years_upfront = value;
        this.log('Setting annual adviser fee years upfront', { value: this._annual_adviser_fee_years_upfront }, 'INFO');
        this.recalculateTotals();
    }

    get annual_adviser_fee_collection() {
        return this._annual_adviser_fee_collection || FeeCollectionEnum.FACILITATED_PLATFORM;
    }
    set annual_adviser_fee_collection(value) {
        this._annual_adviser_fee_collection = value;
        this.log('Setting annual adviser fee collection', { value: this._annual_adviser_fee_collection }, 'INFO');
        this.recalculateTotals();
    }

    get annual_adviser_fee_vat() {
        return this._annual_adviser_fee_vat;
    }
    set annual_adviser_fee_vat(value) {
        this._annual_adviser_fee_vat = value;
    }

    // Annual Platform Fee

    get annual_platform_fee() {
        return floor(this._annual_platform_fee, 2, null);
    }
    get annual_platform_fee_total() {
        return floor(this.$m(this.annual_platform_fee).multiply(this.annual_platform_fee_years_upfront).value, 2);
    }
    get annual_platform_fee_by_collection() {
        return this.annual_platform_fee_total;
    }
    set annual_platform_fee(value) {
        this._annual_platform_fee = this.fromMoney(value, null);
        this.log('Setting annual platform fee', { value: this._annual_platform_fee }, 'INFO');
        this.recalculateAnnualPlatformFee(0, 'percentage');
        this.recalculateTotals();
    }

    get annual_platform_fee_percentage() {
        return this._annual_platform_fee_percentage;
    }
    set annual_platform_fee_percentage(value) {
        this._annual_platform_fee_percentage = value;
        this.log('Setting annual platform fee percentage', { value: this._annual_platform_fee_percentage }, 'INFO');
        this.recalculateAnnualPlatformFee(0, 'amount');
        this.recalculateTotals();
    }

    get annual_platform_fee_use_percentage() {
        return this._annual_platform_fee_use_percentage;
    }
    set annual_platform_fee_use_percentage(value) {
        this._annual_platform_fee_use_percentage = value;
        this.log(
            'Setting annual platform fee use percentage',
            { value: this._annual_platform_fee_use_percentage },
            'INFO'
        );
        this.recalculateTotals();
    }

    get annual_platform_fee_years_upfront() {
        return this._annual_platform_fee_years_upfront || 0;
    }
    set annual_platform_fee_years_upfront(value) {
        this._annual_platform_fee_years_upfront = value;
        this.log(
            'Setting annual platform fee years upfront',
            { value: this._annual_platform_fee_years_upfront },
            'INFO'
        );
        this.recalculateTotals();
    }
}

export default ProposalCalculator;
